<script setup>
import $$ from '@/tools';
import { reactive } from 'vue';
const state = reactive({
    show: false,
    idx: 0,
    url: []
})


function onClose() {
    state.show = false
}
function onSwitch(index) {
    state.idx = index
}

$$.preview = function (url, idx) {
    state.show = true
    state.idx = idx
    state.url = url
}
</script>

<template>
    <el-image-viewer v-if="state.show"
        :initial-index="state.idx"
        :onClose="onClose"
        :onSwitch="onSwitch"
        :url-list="state.url" />
</template>
