<script setup>
import { ElMessage } from "element-plus";
import { computed } from "vue";
import { useGlobalStore } from "../../store";
import $$ from "@/tools";

const globalStore = useGlobalStore();

const item = computed(
  () =>
    globalStore.state.mainInvitationData[
    globalStore.state.mainInvitationChoice
    ] || {}
);
// {
//   adminName: "13118838529",
//   adminId: 1,
//   serviceObjectName: "一味乐",
//   serviceObjectId: 1,
//   addTime: "2024-08-12 09:54:03",
//   __notification__: {},
// };

// methods
function submit(agree) {
  const successCB = (res) => {
    ElMessage.success(
      agree
        ? "加入成功，请联系管理员赋予角色权限"
        : `成功拒绝“${item.value.adminName}”的主体加入邀请`
    );

    item.value.__notification__.close();
    delete globalStore.state.mainInvitationData[
      globalStore.state.mainInvitationChoice
    ];
    globalStore.state.mainInvitationShow = false;
    globalStore.state.mainInvitationChoice = null;
  };
  $$.request({
    url: "system/updateAdminServiceObjectConfirm",
    data: {
      serviceObjectId: item.value.serviceObjectId,
      confirmFlag: agree, //接受，false:拒绝
    },
  })
    .then(successCB)
    .catch((err) => {
      globalStore.state.mainInvitationShow = false;
    });
}
// methods
</script>

<template>
  <el-dialog v-model="globalStore.state.mainInvitationShow"
    width="24%"
    title="主体加入邀请"
    destroy-on-close
    align-center>
    <div class="tipInfo mt_M">
      用户
      <span>{{ item.adminName }}</span>
      于
      <span>{{ item.addTime }}</span>
    </div>
    <div class="tipInfo mt_M">
      邀请您加入主体：
      <span>{{ item.serviceObjectName }}</span>
    </div>
    <template #footer>
      <div>
        <el-button type="danger"
          @click="submit(false)">拒绝</el-button>
        <el-button type="primary"
          @click="submit(true)">同意</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.tipInfo {
  span {
    font-size: 1.2em;
    color: var(--self-mainColor);
  }
}
</style>
