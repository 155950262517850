<script setup>
import System from "./pages/System.vue";
import dayjs from "dayjs";
console.log(dayjs().format("YYYY-MM-DD HH:mm"));
</script>

<template>
  <System />
</template>

<style scoped></style>
