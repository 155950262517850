const cf = {
  // 主色号
  "--self-mainColor": "#fd3904",
  "--self-mainBlueColor": "#0052cc",
  "--self-mainGreenColor": "#52c41a",
  "--self-mainRedColor": "#f5222d",
  // 动画时长
  "--self-transitionTime": "0.3s",
  // 背景色
  "--self-darkBackgroundColor": "#f1f5f9",

  // 边框
  "--self-border": "1px solid #dbdbdb",
};

Object.entries(cf).forEach(([key, val]) => {
  app.style.setProperty(key, val);
});

export default cf;
