import { ElMessage } from "element-plus";
import { nextTick, reactive } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "../store";
/**
 * @type {import('vue-router').Router}
 */
let router = null;
class RouterCache {
  constructor() {
    this.state = reactive({
      list: [],
      store: {},
      checked: "",
      closeCB: {},
    });
  }
  init() {
    if (router) return;
    router = useRouter();
  }

  clear() {
    this.state.list = [];
    this.state.store = {};
    this.state.checked = "";
    router?.replace("/_");
  }
  register(route) {
    this.init();
    if (!route.meta.name || ["/_", "/Login"].includes(route.path)) return;
    if (!this.state.store[route.path]) {
      this.state.list.push(route.path);
      this.state.store[route.path] = {
        fullPath: route.fullPath,
        hash: route.hash,
        meta: route.meta,
        name: route.name,
        params: route.params,
        path: route.path,
        query: route.query,
      };
    }
    this.state.checked = route.path;
  }
  change(path, closeCB) {
    if (path == this.state.checked) return;
    this.state.checked = path;
    this.setCloseCB(path, closeCB);

    if (router) router.push(path);
    else {
      ElMessage.error("页面失效，请重新登录");
      useUserStore().actions.logout();
    }
  }
  setCloseCB(path, closeCB) {
    this.state.closeCB[path] = closeCB;
  }
  async close(path) {
    let closeRes;
    if (this.state.closeCB[path]) {
      try {
        closeRes = await this.state.closeCB[path]();
      } catch (err) {
        ElMessage.error(err);
        console.log(err);
        closeRes = false;
      }
    }
    if (closeRes === false) return;
    const listIdx = this.state.list.findIndex((_) => _ == path);
    this.state.list.splice(listIdx, 1);
    delete this.state.store[path];
    if (path == this.state.checked) {
      router.go(-1);
    }
  }
}

export default new RouterCache();
