import { createApp } from "vue";
import App from "./App.vue";

import "./styles/var.js";
import "./styles/global.scss";
import "./styles/public.scss";
// import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-notification.css";
import "element-plus/theme-chalk/el-message-box.css";

const app = createApp(App);

import router from "./router";
app.use(router);

import { createPinia } from "pinia";
const pinia = createPinia();
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

import globalComponents from '@/components/global';
app.use(globalComponents)

app.mount("#app");
