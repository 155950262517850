<script setup>
import PageLogin from "./PageLogin.vue";
import TheFrame from "./TheFrame.vue";
// 引入中文包
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import GlobalComponents from "../components/global/index.vue";

import { useUserStore } from "../store";
const userStore = useUserStore();
</script>
<template>
  <!-- size="small" -->
  <el-config-provider :locale="zhCn">
    <div class="flex WandH_100p p_R">
      <TheFrame v-if="userStore.state.login" />
      <PageLogin v-else />

      <GlobalComponents />
    </div>
  </el-config-provider>
</template>

<style scoped lang="scss"></style>
