import axios from "axios";
import { ElMessage } from "element-plus";
import { useUserStore } from "../store";

const baseURL = "/work/"; //import.meta.env ? "/" : "/api/";

/**
 * 使用指定选项发起请求。
 * @param {Object} opt - 请求选项。
 * @param {string} opt.url - 请求的URL地址（必填）。
 * @param {'post'|'get'|'put'|'delete'} [opt.method='post'] - 请求方式（默认为'post'）。
 * @param {Object} [opt.data] - 以查询字符串形式发送的数据。
 * @param {Object} [opt.params] - 包含在请求中的URL参数（param形式）。
 * @param {'json'|'arraybuffer'|'document'|'text'|'stream'|'blob'} [opt.responseType='json'] - 期望的返回值类型（默认为'json'）。
 * @param {string} [opt.baseURL='/work'] - 请求的基本前缀（默认为'/work'）。
 * @param {boolean} [opt.use_defaultCatch] - 指示是否使用默认的错误处理方式。
 */
function request(opt) {
  const userStore = useUserStore();
  return new Promise((resolve, reject) => {
    const nullObj = Object.create(null);
    opt.use_defaultCatch = opt.use_defaultCatch !== false;
    if (opt.use_formData) {
      const data = new FormData();

      // 遍历 opt.data 的键值对并添加到 FormData 中
      Object.keys(opt.data).forEach((key) => {
        const value = opt.data[key];

        if (Array.isArray(value)) {
          // 如果值是数组，对数组中的每个值都追加
          value.forEach((item) => {
            data.append(key, item);
          });
        } else {
          // 如果值是普通类型，直接追加
          data.append(key, value);
        }
      });

      // 赋值回 opt.data 或直接使用
      opt.data = data;
    }
    axios({
      baseURL: opt.baseURL !== undefined ? opt.baseURL : baseURL,
      url: opt.url,
      method: opt.method || "post",
      params: opt.params || nullObj,
      data: opt.data || nullObj,
      responseType: opt.responseType || "json",
      headers: {
        Authorization: "Bearer " + userStore.state.token,
      },
    })
      .then((res) => {
        switch (res.data.code) {
          case 200:
            if (opt.use_dataSource) resolve(res);
            else resolve(res.data);
            return;
          case 401:
            userStore.actions.logout(true);
            break;
          default:
            break;
        }
        if (opt.use_defaultCatch) {
          ElMessage.error(res.data.message);
        }
        reject(res.data);
      })
      .catch((err) => {
        if (opt.use_defaultCatch) {
          if (err?.response?.status == 401) {
            userStore.actions.logout(true);
            ElMessage.error("登录凭证过期，请重新登录");
          } else {
            ElMessage.error(err?.response?.data?.message || err);
          }
        }
        reject(err);
      });
  });
}

request.baseURL = baseURL;
export default request;
