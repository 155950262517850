import { createRouter, createWebHashHistory } from "vue-router";
import { useUserStore } from "../store";
import routeCache from "./routeCache";

import CONFIG from "./config";

const router = createRouter({
  history: createWebHashHistory(),
  routes: CONFIG,
});

router.beforeEach((to, from) => {
  // ...
  // 返回 false 以取消导航
  // beforeEach
  // console.log("beforeEach", to);
  const userStore = useUserStore();

  // 没登陆
  if (to.name == "Login") {
    if (userStore.state.login) return false;
  } else {
    if (!userStore.state.login) {
      userStore.actions.logout();
      return false;
    } else if (!to.meta.name) {
      // 没这个路由
      return false;
    }
    routeCache.register(to);
  }
});

export default router;
