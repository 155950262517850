<script setup>
import { ElMessage } from "element-plus";
import { Plus, Minus } from "@element-plus/icons-vue";
import { computed, reactive, watch, ref, nextTick } from "vue";
import { useGlobalStore } from "../../store";
import $$ from "../../tools";

const globalStore = useGlobalStore();

const tableRef = ref(null)
  , formRef = ref(null)

const state = reactive({
  loading: false,
  loadingBox: false
})


const fields = $$.initFields([
  { label: `物料名称`, key: "name", formUse: false, width: 160 },
  { label: "物料编码", key: "code", formUse: false },
  { label: "产品分类", key: "typeName", formUse: false },
  { label: "规格", key: "requirement", formUse: false, width: 120 },
  { label: "BOM单位", key: "bomUnit", formUse: false },
  { label: "需求量", key: "bomUseQuantity", formUse: false },
  { label: "采购单位", key: "unit", formUse: false },
  {
    label: "采购数量", key: "purchaseQuantity",
    formUse: true
  },
  {
    label: "到货日期", key: "targetDate", width: 90,
    formUse: { type: 'datePicker' }
  },
]);
const fields_haveOption = [...fields.table, $$.constant.tableOptionColumn]


const formOpt = $$.initFormOption({
  config: fields.form,
  editSubmit(data) {
    if (!this.editData._type_) data._type_ = 'edit'
    Object.assign(this.editData, data)
    this.show = false
  },
});


watch(
  () => globalStore.state.procurementDetailShow,
  (n) => {
    nextTick(() => {
      if (n) {
      } else {
        globalStore.state.procurementDetailCB = null
        globalStore.state.procurementDetailRes = {}
      }
    })
  }
)

const data = computed(() => globalStore.state.procurementDetailRes)
  , isCheck = computed(() => globalStore.state.procurementDetailType == 'check')

// methods
function submit() {
  if (!data.value.items.length) ElMessage.warning('请选择需要采购的物料')
  formRef.value
    .validate()
    .then(async (validate) => {
      if (validate) {
        state.loadingBox = true
        const CBRes = await globalStore.state.procurementDetailCB(data.value).catch(err => err)
        state.loadingBox = false
        if (CBRes) return ElMessage.error(CBRes)

        globalStore.state.procurementDetailShow = false
      }
    })
    .catch((err) => {
      console.log("err", err);
      ElMessage.warning("请检查表单");
    });
}

function checkEarliestTargetDate() {
  const tf = data.value.earliestTargetDate
  if (!tf) ElMessage.error('请先选择到货日期')
  return tf
}

function showMaterialDialog() {
  globalStore.actions.showMaterialChoice((res) => new Promise((resolve) => {
    const unQuantity = res.find(item => !(item.quantity))
    if (unQuantity) return resolve(`请输入物料“${unQuantity.name}”的需求数量`)

    const successCB = (res) => {
      // 选择完物料
      data.value.items.push(
        ...res.data.materialList.map(item => {
          item._type_ = 'add'
          item._globalId_ = $$.getGlobalId()
          item.unit = item.unit
          return item
        })
      )
      resolve()
    };

    $$.request({
      url: "product/calPurchaseApply",
      data: {
        materialArray: res.map((item) => ({
          materialId: item.id,
          targetDate: data.value.earliestTargetDate,
          bomUseQuantity: item.quantity
        }))
      }
    })
      .then(successCB)
      .catch(err => { console.log(err); resolve(err) })

  }))
}

// methods
</script>

<template>
  <el-dialog v-model="globalStore.state.procurementDetailShow"
    width="52%"
    :title="{ add: '添加', edit: '编辑', check: '查看' }[globalStore.state.procurementDetailType] + '单据'"
    destroy-on-close
    align-center>
    <el-form class="H100_p flex _fdC"
      ref="formRef"
      :model="data"
      style="height: 50vh;"
      :disabled="isCheck"
      v-loading="!globalStore.state.procurementDetailCB || state.loadingBox"
      v-bind="$$.constant.loadingBind">
      <div class="formBox flex _fwW">
        <el-form-item label="单号"
          class="formItem">
          {{ data.code || '创建后系统自动生成' }}
        </el-form-item>
        <el-form-item label="采购日期"
          prop="purchaseDate"
          :rules="[{ required: true, message: `请选择采购日期`, trigger: 'change' }]"
          class="formItem">
          <el-date-picker class="f1"
            v-model="data.purchaseDate"
            value-format="YYYY-MM-DD"
            placeholder="请选择采购日期" />
        </el-form-item>
        <el-form-item label="到货日期"
          prop="earliestTargetDate"
          :rules="[{ required: true, message: `请选择到货日期`, trigger: 'change' }]"
          :error="data.earliestTargetDate ? '' : '请选择到货日期'"
          class="formItem">
          <el-date-picker class="f1"
            v-model="data.earliestTargetDate"
            value-format="YYYY-MM-DD"
            placeholder="请选择采购日期" />
        </el-form-item>
        <el-form-item label="状态"
          class="formItem">
          <span v-if="data.status"
            :style="{ color: $$.constant.optionRelation.procurementRequestStatus[data.status].color }">{{
              $$.constant.optionRelation.procurementRequestStatus[data.status].name
            }}</span>
        </el-form-item>

        <el-form-item label="备注"
          style="width: calc(50% - 4px);"
          class="formItem">
          <el-input class="f1"
            v-model="data.remark"
            clearable
            show-word-limit
            type="textarea"
            :autosize="{ minRows: 2 /* , maxRows: 4 */ }"
            placeholder="请输入备注" />
        </el-form-item>
      </div>

      <div class="W_100p p_R">
        <el-button style="position: absolute; transform:translateY(-100%); right: 0; top: -8px;"
          type="primary"
          @click="() => checkEarliestTargetDate() && showMaterialDialog()">
          添加物料
        </el-button>
      </div>

      <Table class="f1_oH"
        ref="tableRef"
        autoPagination
        rowId="_globalId_"
        :columnConfig="isCheck ? fields.table : fields_haveOption"
        :tableData="data.items || []"
        :loading="state.loading"
        :config="{ size: 'small' }"
        :paginationConfig="{ size: 'small' }">
        <template #option="scope">
          <el-button class="bluePrimaryLinkBtn"
            link
            @click="formOpt.editData = scope.row">
            编辑
          </el-button>

          <el-button type="danger"
            link
            @click="scope.row.purchaseQuantity = 0; !scope.row._type_ && (scope.row._type_ = 'edit')">
            清零
          </el-button>
        </template>
      </Table>
    </el-form>
    <template #footer>
      <div>
        <el-button @click="globalStore.state.procurementDetailShow = false">关闭</el-button>
        <el-button type="primary"
          v-if="!isCheck"
          @click="submit">提交</el-button>
      </div>
    </template>
  </el-dialog>

  <AddAndEditDialog title="编辑物料信息"
    :addBtnShow="false"
    :config="$$.constant.useColEditDialog"
    column='1'
    width="18%"
    v-model:editData="formOpt.editData"
    v-model:show="formOpt.show"
    :fieldConfig="formOpt.config"
    :loading="formOpt.loading"
    @edit="formOpt.editSubmit">

    <template #before_table>
      <div class="mb_L"
        v-if="formOpt.editData">
        请输入物料:
        <span style="color: var(--self-mainColor);font-size: 1.1em;">{{ formOpt.editData.name }}</span>
        采购的数量，采购单位:
        <span style="color: var(--self-mainColor);font-size: 1.1em;">{{ formOpt.editData.bomUnit }}</span>
      </div>
    </template>
  </AddAndEditDialog>
</template>

<style scoped lang="scss">
.formBox {
  column-gap: 8px;

  .formItem {
    width: calc(25% - 6px);
  }
}
</style>
