<script setup>
import { useUserStore } from "../../store";

const userStore = useUserStore();

const choiceId = defineModel("choiceId", { type: Number });
</script>

<template>
  <div class="choiceSubjectBox">
    <div
      class="subjectItemBox"
      v-for="item in userStore.state.loginInfo.serviceObjectList"
      :class="choiceId == item.id && 'choicedSubject'"
      :key="item.id"
      @click="choiceId = item.id">
      <div class="subjectItemRadio"></div>
      {{ item.name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.choiceSubjectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  .subjectItemBox {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 7em;
    font-size: 1.2em;
    transition: all var(--self-transitionTime);
    background-color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    .subjectItemRadio {
      box-sizing: border-box;
      width: 0.9em;
      height: 0.9em;
      border-radius: 50%;
      border: 2px solid #999;
      transition: all var(--self-transitionTime);
    }
  }
  .choicedSubject {
    color: var(--self-mainColor);
    .subjectItemRadio {
      border: 5px solid var(--self-mainColor);
    }
  }
}
</style>
