<script setup>
import { ref } from 'vue';
import $$ from '../../tools';
import { useUserStore } from "../../store";
import { nextTick } from 'vue';

const userStore = useUserStore();

window.$$ = $$
const config = ref({})
    , fileList = ref([])
    , uploadRef = ref(null)

$$.uploadFile = function (coverConfig) {
    uploadRef.value.clearFiles()
    config.value = coverConfig
    nextTick(() => {
        uploadRef.value.$el.querySelector('input').click()
    })
}

</script>

<template>
    <el-upload ref="uploadRef"
        :headers="{ Authorization: 'Bearer ' + userStore.state.token, }"
        :show-file-list="false"
        v-model:file-list="fileList"
        v-bind="config">
    </el-upload>
</template>