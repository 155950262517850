<script setup>
import { ElMessage } from "element-plus";
import { Plus, Minus } from "@element-plus/icons-vue";
import { computed, shallowReactive, watch, ref, nextTick } from "vue";
import { useGlobalStore } from "../../store";
import $$ from "../../tools";

const globalStore = useGlobalStore();

const tableRef = ref(null)

const state = shallowReactive({
  loadingBox: false,
  loading: false,
  searchFieldVal: {},
  tableData: []

  , table_allTypeData: []
})


const fields = $$.initFields([
  {
    label: `物料类型`, key: "typeId", external: true, type: 'treeSelect',
    searchUse_only: true
  },
  {
    label: `物料名称`, key: "name",
    searchUse: { external: true },
  },
  {
    label: "物料编码", key: "code",
    searchUse: { external: true },
  },
  { label: "单位", key: "unit", },
  { label: "规格型号", key: "requirement", tooltip: true, },
  { label: "备注", key: "remark", width: 180, tooltip: true, },
  { ...$$.constant.tableOptionColumn, config: { width: 60 } },
], { formUse: false });



watch(
  () => globalStore.state.materialChoiceShow,
  (n) => {
    nextTick(() => {
      if (n) {
        search(true)
        search_allType()
      } else {
        state.searchFieldVal = {}
        state.tableData = []
      }
    })
  }
)



// methods
function search(initCurrentPage = false) {
  state.loading = true
  if (initCurrentPage) tableRef.value.initCurrentPage();
  const successCB = (res) => {
    state.tableData = res.data.resList;
    tableRef.value.setTotal(res.data.pageTotalNum);
  };
  $$.request({
    url: "data/getMaterialList",
    method: "get",
    params: {
      pageSize: tableRef.value.getPageSize(),
      pageNum: tableRef.value.getCurrentPage(),
      ...state.searchFieldVal,
    },
  }).then(successCB)
    .finally(() => (state.loading = false));
}
function search_allType() {
  const successCB = (res) => {
    state.table_allTypeData = res.data;
  };
  $$.request({
    url: "data/getMaterialType?simpleFlag=true",
    method: "get",
  }).then(successCB);
}

async function submit() {

  state.loadingBox = true
  const CBRes = await globalStore.state.materialChoiceCB(Object.values(globalStore.state.materialChoiceRes))
  state.loadingBox = false
  if (CBRes) return ElMessage.error(CBRes)

  globalStore.state.materialChoiceShow = false
}

function action(type, data) {
  switch (type) {
    case 'add':
      globalStore.state.materialChoiceRes[data.id] = data
      break;
    case 'del':
      delete globalStore.state.materialChoiceRes[data.id]
      break;

  }
}
// methods
</script>

<template>
  <el-dialog v-model="globalStore.state.materialChoiceShow"
    width="60%"
    title="物料选择"
    destroy-on-close
    align-center>
    <div class="flex _fdC"
      style="height: 60vh;"
      v-loading="state.loadingBox"
      v-bind="$$.constant.loadingBind">

      <SearchBar @search="(data) => {
        state.searchFieldVal = data;
        search(true);
      }
        "
        :config="fields.search"
        :optionData="{
          typeId: state.table_allTypeData,
        }">
      </SearchBar>

      <div class="flex f1_oH gapM">
        <Table class="o_A "
          style="flex:4"
          ref="tableRef"
          @search="search"
          :columnConfig="fields.table"
          :tableData="state.tableData"
          :loading="state.loading"
          :config="{ size: 'small' }"
          :paginationConfig="{ size: 'small', 'pager-count': 5, }">
          <template #option="scope">
            <el-button style="width: 20px;height: 20px;"
              :disabled="Boolean(globalStore.state.materialChoiceRes[scope.row.id])"
              type="success"
              size="small"
              circle
              @click="action('add', scope.row)"
              :icon="Plus" />
          </template>
        </Table>

        <Table class="o_A "
          style="flex:3"
          autoPagination
          :columnConfig="[
            { label: `物料名称`, key: 'name', },
            { label: '物料编码', key: 'code', },
            { label: '单位', key: 'unit', },
            { label: '数量', key: 'quantity', },
            { ...$$.constant.tableOptionColumn, config: { width: 60 } },
          ]"
          :tableData="Object.values(globalStore.state.materialChoiceRes)"
          :loading="false"
          :config="{ size: 'small' }"
          :paginationConfig="{ size: 'small' }">

          <template #quantity="scope">
            <el-input type="text"
              size="small"
              v-model="scope.row.quantity" />
          </template>
          <template #option="scope">
            <el-button style="width: 20px;height: 20px;"
              type="danger"
              size="small"
              circle
              @click="action('del', scope.row)"
              :icon="Minus" />
          </template>
        </Table>
      </div>

    </div>
    <template #footer>
      <div>
        <el-button @click="globalStore.state.materialChoiceShow = false">关闭</el-button>
        <el-button type="primary"
          @click="submit()">提交</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss"></style>
