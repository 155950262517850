<script setup>
import { User, Message, MessageBox, Download } from "@element-plus/icons-vue";
import { nextTick, onMounted, reactive, ref } from "vue";
import { useUserStore, useGlobalStore } from "../../store";
import SubjectChoice from "../../components/PageLogin/SubjectChoice.vue";
import { ElMessage } from "element-plus";
import routeCache from "../../router/routeCache";
import RSA from "../../tools/RSA"
import $$ from "../../tools";
import _ from 'lodash';
import { ElNotification } from "element-plus";

const userStore = useUserStore()
  , globalStore = useGlobalStore();

const informationScrollRef = ref(null)

const state = reactive({
  subjectDialog: {
    show: false,
    id: userStore.state.loginInfo.serviceObjectId,
  },

  information: {
    list: [],
    num: null,
    show: false,
    loading: true,
  },

  task: {
    list: [],
    num: null,
    show: false,
    loading: true,
  }
});
const informationConfig = {
  pageSize: 10,
  pageNum: 1,
  total: 0,
  notification: {},
  refreshTime: 1000 * (import.meta.env.DEV ? 60 * 10 : 10),
  msgCloseTime: 1000 * 7.5
}

const taskConfig = reactive({
  pageSize: 10,
  pageNum: 1,
  total: 0,
  refreshTime: 1000 * 3
})

const formOpt_password = reactive({
  show: false,
  editData: null,
  config: $$.initFields([
    {
      label: '旧密码', key: 'oldPassword', required: true, wide: true,
      formUse_only: true
    },
    {
      label: '新密码', key: 'newPassword', required: true, wide: true,
      formUse_only: true
    }
  ]).form,
  editSubmit(data) {
    const successCB = (res) => {
      formOpt_password.show = false;
      ElMessage.success(`密码修改成功`);
    };
    $$.request({
      url: "system/updatePassword",
      data: {
        operate: 'update',
        oldPassword: RSA.encrypt(data.oldPassword, RSA.key.password),
        newPassword: RSA.encrypt(data.newPassword, RSA.key.password),
      },
    }).then(successCB);
  },
});

// function test11(e) {
//   console.log("test11", e.srcElement.dataset);
// }
// $$.notification({
//   title: "Prompt",
//   dangerouslyUseHTMLString: true,
//   message: "<strong >This is <i data-test='html'>HTML</i> string</strong>",
//   duration: 0,
//   onClick: test11,
// });

onMounted(() => {
  getUnreadInformation(5)
})

// methods
function changeSubject() {
  const successCB = (res) => {
    if (userStore.actions.checkLoginInfo(res.data)) return;
    ElMessage.success("成功绑定主体");
    Object.assign(userStore.state.loginInfo, res.data);
    userStore.state.loginInfo.isAdmin = userStore.state.loginInfo.moduleIds.includes('s_1')
    routeCache.clear();
    nextTick(() => {
      userStore.actions.login();
      state.subjectDialog.show = false;
      state.subjectDialog.id = userStore.state.loginInfo.serviceObjectId;
    });
  };
  $$.request({
    url: "system/updateUserServiceObject",
    data: {
      serviceObjectId: state.subjectDialog.id,
    },
  })
    .then(successCB)
    .catch((err) => {
      console.log(err);
      // state.choiceSubject = false;
    });
}
// 获取未读的信息数量
let getUnreadInformationTimer

function getUnreadInformation(checkNum = 1) {
  if (!userStore.state.login) return
  clearTimeout(getUnreadInformationTimer)
  getUnreadInformationTimer = setTimeout(getUnreadInformation, informationConfig.refreshTime)

  const successCB = (res) => {
    if (res.data.unreadMessageCount > state.information.num) {
      // 有消息了
      $$.request({
        url: "system/getSiteMessages",
        baseURL: '/system/',
        method: 'get',
        params: {
          pageNum: 1,
          pageSize: checkNum,
        }
      }).then(async res => {
        if (res.data.siteMessageList) {
          for (const item of res.data.siteMessageList || []) {
            if (item.status == 1 && !informationConfig.notification[item.id]) {
              const close = () => {
                if (!informationConfig.notification[item.id]) return
                informationConfig.notification[item.id].close()
                delete informationConfig.notification[item.id]
              }
              // 未读且没过弹消息
              await new Promise((resolve) => {
                informationConfig.notification[item.id] = ElNotification({
                  icon: item.type == 1 && Download,
                  title: "收到新的消息！",
                  dangerouslyUseHTMLString: true,
                  message: `
  <div class="c_P">
    <div class="fw_B mb_S" style="font-size: 1.16em" >
      ${item.description}
    </div>
    <div>${item.createTime}</div>
  </div>`,
                  duration: informationConfig.msgCloseTime,
                  appendTo: "#globalNotification",
                  onClose: close,
                  onClick() {
                    clickInformation(item)
                    close()
                    // setTimeout(refreshInformation, 200)
                  },
                });
                setTimeout(resolve, 250);
              })
            }
          }
        }

      })

    }
    state.information.num = res.data.unreadMessageCount
  };

  $$.request({
    url: "system/getUnreadMessageCount",
    baseURL: '/system/',
    method: 'get'
  }).then(successCB)
}

// 刷新消息
function refreshInformation() {
  state.information.show = false;
  initInformation()
}

// 点击任务
globalStore.actions.showTask = () => { state.task.show = false; initTask() }
function initTask() {
  if (state.task.show) return state.task.show = false
  state.task.show = true
  state.task.list = []
  taskConfig.pageNum = 1

  getTaskList()
}

// 获取任务列表 
let getTaskListTimer
function getTaskList() {
  state.task.loading = true
  clearTimeout(getTaskListTimer)
  const successCB = (res) => {
    state.task.loading = false
    state.task.list = res.data.taskSchedules || []
    taskConfig.total = res.data.total

    if (state.task.list.find(item => [1, 2].includes(item.status))) {
      getTaskListTimer = setTimeout(getTaskList, taskConfig.refreshTime)
    }
  };

  $$.request({
    url: "system/getTaskInfo",
    baseURL: '/system/',
    method: 'get',
    params: {
      pageSize: taskConfig.pageSize,
      pageNum: taskConfig.pageNum
    }
  }).then(successCB)
}
// 点击信息 
function initInformation() {
  if (state.information.show) return state.information.show = false
  state.information.show = true
  state.information.list = []
  informationConfig.pageNum = 1

  getUnreadInformation()
  getInformationList()
}
// 获取信息列表 
function getInformationList() {
  state.information.loading = true
  const successCB = (res) => {
    state.information.loading = false
    if (res.data.siteMessageList) state.information.list.push(...res.data.siteMessageList)
    informationConfig.total = res.data.total
  };


  $$.request({
    url: "system/getSiteMessages",
    baseURL: '/system/',
    method: 'get',
    params: {
      pageSize: informationConfig.pageSize,
      pageNum: informationConfig.pageNum
    }
  }).then(successCB)
}

function setInformationReaded(messageItems) {
  const successCB = (res) => {
    if (messageItems) {
      messageItems.forEach(item => {
        item.status = 2
        // state.information.num -= 1
        getUnreadInformation()
      })
    } else {
      state.information.num = 0
      state.information.show = false;
      initInformation()
    }
  };

  $$.request({
    url: `system/${messageItems ? 'updateReadStatus' : 'updateAllMsgToRead'}`,
    baseURL: '/system/',
    data: {
      messageIds: messageItems?.map(item => item.id)
    }
  }).then(successCB)
}

const scrollInformation = _.debounce((e) => {
  if (state.information.loading) return
  const isBottom = informationScrollRef.value.wrapRef.scrollHeight - e.scrollTop - informationScrollRef.value.wrapRef.clientHeight <= 16
  if (isBottom && informationConfig.total != state.information.list.length) {
    informationConfig.pageNum++
    getInformationList()
  }
}, 200)

// 消息列表点击事件
function clickInformation(row) {
  /**
   * type 
   *    1:有个url,下载东西
   *    2:具体看behaviorId, 前后端一起定义的行为
   */
  switch (row.type) {
    case 1:
      $$.showFile(row)
      break;
    case 2:
      switch (row.behaviorId) {
        case 1:
          // 跳转物料管理
          routeCache.change('/Information/MaterialManagement')
          break;
        case 2:
          // 跳转物料分类
          routeCache.change('/Information/MaterialClassification')
          break;
        case 3:
          // 跳转客户页面
          routeCache.change('/Information/CustomerManagement')
          break;
        case 4:
          // 跳转供应商页面
          routeCache.change('/Information/SupplierManagement')
          break;
      }
      break;
  }

  if (row.status == 1) setInformationReaded([row])
}

// 点击任务
function clickTask(row) {
  if (row.downloadFlag) $$.showFile(row)
}

// methods
</script>

<template>

  <div @click="initTask">
    <el-button class="mr_M"
      style="font-size: 1.25em"
      size="large"
      type="primary"
      :icon="MessageBox"
      circle
      text
      plain />

    <el-popover v-model:visible="state.task.show"
      :teleported="false"
      popper-style="padding: 8px"
      width="320"
      trigger="contextmenu">
      <template #reference>
        <div></div>
      </template>
      <div @click.stop
        v-loading="state.task.loading"
        v-bind="$$.constant.loadingBind">
        <div class="mb_S ta_R">
          <el-button class="bluePrimaryLinkBtn"
            link
            @click="getTaskList">
            刷新
          </el-button>
        </div>
        <template v-if="state.task.list.length">
          <el-scrollbar ref="taskScrollRef"
            max-height="32vh">
            <div class="informationBox taskBox"
              v-for="(item) in state.task.list"
              @click="clickTask(item)"
              :key="item.id">

              <div class="flex _aiC">
                <span style="color: #999">{{ item.createTime }}</span>

                <div class="ml_A"></div>
                <el-icon v-if="item.downloadFlag">
                  <Download />
                </el-icon>
                <span class="ml_S"
                  :style="{
                    color: $$.constant.optionRelation.taskStatus[item.status].color
                  }">{{ $$.constant.optionRelation.taskStatus[item.status].name }}</span>
              </div>
              <div class="informationContentBox"
                style="font-size: 1.05em;">
                {{ item.description }}
              </div>


              <div class="flex _aiC">
                <div class="progressBarBox f1">
                  <div :style="{
                    width: item.progress + '%',
                    backgroundColor: $$.constant.optionRelation.taskStatus[item.status].color
                  }"></div>
                </div>

                <span style="width: 3em;text-align: right;">{{ item.progress }}%</span>
              </div>
            </div>
          </el-scrollbar>

          <el-pagination class="mt_M"
            @current-change="getTaskList"
            background
            v-model:current-page="taskConfig.pageNum"
            v-model:page-size="taskConfig.pageSize"
            layout="total, ->, prev, pager, next"
            size="small"
            :total="taskConfig.total">
          </el-pagination>
        </template>
        <el-empty v-else
          :image-size="150"
          description="暂无数据" />
      </div>
    </el-popover>
  </div>

  <div @click="initInformation"
    class="mr_L">
    <el-badge :hidden="!+state.information.num"
      :value="+state.information.num"
      :offset="[-6, 6]">
      <el-button style="font-size: 1.25em"
        size="large"
        type="primary"
        :icon="Message"
        circle
        plain />
    </el-badge>

    <el-popover v-model:visible="state.information.show"
      :teleported="false"
      popper-style="padding: 8px"
      width="300"
      trigger="contextmenu">
      <template #reference>
        <div></div>
      </template>
      <div @click.stop
        v-loading="state.information.loading"
        v-bind="$$.constant.loadingBind">
        <div class="mb_S ta_R">
          <el-button class="bluePrimaryLinkBtn"
            v-if="state.information.num"
            link
            @click="setInformationReaded()">
            一键已读
          </el-button>
          <el-button class="bluePrimaryLinkBtn"
            link
            @click="refreshInformation">
            刷新
          </el-button>
        </div>
        <el-scrollbar v-if="state.information.list.length"
          ref="informationScrollRef"
          @scroll="scrollInformation"
          max-height="40vh">
          <div class="informationBox"
            v-for="(item) in state.information.list"
            @click="clickInformation(item)"
            :key="item.id">

            <div class="flex _aiC">
              <span style="color: #999">{{ item.createTime }}</span>

              <div class="ml_A"></div>
              <el-icon v-if="item.type == 1">
                <Download />
              </el-icon>
              <span class="ml_S"
                :style="{
                  color: $$.cssVar[item.status == 1 ? '--self-mainRedColor' : '--self-mainGreenColor']
                }">{{ item.status == 1 ? '未读' : '已读' }}</span>
            </div>
            <div class="informationContentBox"
              style="font-size: 1.05em;">{{ item.description }}</div>

          </div>
          <div class="ta_C"
            style="color: #999;">{{ informationConfig.total != state.information.list.length ? '正在加载更多...'
              : '已经到底了' }}</div>
        </el-scrollbar>
        <el-empty v-else
          :image-size="150"
          description="暂无数据" />
      </div>
    </el-popover>
  </div>

  <el-dropdown>
    <div class="flex _aiC gapS mr_M c_P"
      style="font-size: 1.2em">
      <el-icon>
        <User />
      </el-icon>
      {{ userStore.state.loginInfo.userName }}
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="state.subjectDialog.show = true">
          更换主体
        </el-dropdown-item>
        <el-dropdown-item @click="formOpt_password.editData = {}">
          修改密码
        </el-dropdown-item>
        <el-dropdown-item @click="userStore.actions.logout">
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>

  <el-dialog v-model="state.subjectDialog.show"
    title="更换主体"
    width="400"
    align-center>
    <SubjectChoice class="m_L-"
      v-model:choiceId="state.subjectDialog.id" />
    <template #footer>
      <div>
        <el-button @click="state.subjectDialog.show = false">取消</el-button>
        <el-button :disabled="state.subjectDialog.id == userStore.state.loginInfo.serviceObjectId
          "
          type="primary"
          @click="changeSubject">
          更改
        </el-button>
      </div>
    </template>
  </el-dialog>

  <AddAndEditDialog :addBtnShow="false"
    width="28%"
    title="修改密码"
    v-model:editData="formOpt_password.editData"
    v-model:show="formOpt_password.show"
    :fieldConfig="formOpt_password.config"
    @edit="formOpt_password.editSubmit">
  </AddAndEditDialog>

</template>

<style scoped lang="scss">
.informationBox {
  box-shadow: var(--el-box-shadow-lighter);
  border: var(--self-border);
  transition: border .3s;
  cursor: pointer;

  border-radius: 4px;
  padding: 6px 8px;
  margin: 4px 4px 12px;

  &:hover {
    border: 1px solid var(--self-mainColor);
  }

  .informationContentBox {
    margin-top: 4px;
    padding-top: 6px;
    min-height: 3em;
    border: var(--self-border);
    border-width: 0px;
    border-top-width: 1px;
  }
}

.taskBox {
  .progressBarBox {
    height: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: #eee;
    box-sizing: border-box;
    padding: 3px;

    &>div {
      height: 100%;
      border-radius: 6px;
      transition: width .3s
    }
  }
}
</style>
