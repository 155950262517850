import {
  DataLine,
  Setting,
  FolderOpened,
  TakeawayBox,
  MessageBox,
  Connection,
  Odometer,
} from "@element-plus/icons-vue";
export default {
  page: {
    101: "/Homepage/DataView", //"数据视图",

    201: "/Sale/OrderDetails", // "订单明细",

    301: "/Produce/ProductionSchedulingPlan", // "排产申请",
    302: "/Produce/MaterialRequirements", // "物料需求",
    303: "/Produce/PurchasingRequisition", // "采购申请",
    304: "/Produce/ProductionTasks", // "生产任务",

    401: "/PurchaseSalesInventory/PurchaseOrder", // "采购订单",
    402: "/PurchaseSalesInventory/PurchaseReceipt", // "采购收货",
    403: "/PurchaseSalesInventory/QuotationManagement", // "报价管理",
    404: "/PurchaseSalesInventory/WarehouseManagement", // "入库管理",
    405: "/PurchaseSalesInventory/OutboundManagement", // "出库管理",
    406: "/PurchaseSalesInventory/InventoryCount", // "库存盘点",
    407: "/PurchaseSalesInventory/InventoryManagement", // "库存管理",
    408: "/PurchaseSalesInventory/MaterialOccupationManagement", // "占料管理",
    409: "/PurchaseSalesInventory/ShipmentRequest", // "发货申请",
    410: "/PurchaseSalesInventory/DeliveryManagement", // "发货管理",

    501: "/Finance/CollectionBill", // "收款账单",
    502: "/Finance/PaymentBill", // "付款账单",
    503: "/Finance/ReimbursementAndPayment", // "报销付款",
    504: "/Finance/CostRecord", // "费用记录",

    601: "/Information/MaterialClassification", // "物料分类",
    602: "/Information/MaterialManagement", // "物料管理",
    603: "/Information/BOMmanagement", // "BOM管理",
    604: "/Information/BOMversion", // "BOM版本",
    605: "/Information/CustomerManagement", // "客户管理",
    606: "/Information/SupplierManagement", // "供应商管理",
    607: "/Information/CollectionConfiguration", // "收款配置",
    608: "/Information/DiscountedConfiguration", // "优惠配置",

    701: "/System/DepartmentManagement", // "部门管理",
    702: "/System/RoleManagement", // "角色管理",
    703: "/System/UserManagement", // "用户管理",
    704: "/System/SystemManagement", // "系统管理",
  },
  icon: {
    1: DataLine, // "主页",
    2: Connection, // "销售",
    3: Odometer, // "生产",
    4: TakeawayBox, // "进销存",
    5: MessageBox, // "财务",
    6: FolderOpened, // "资料",
    7: Setting, // "系统",
  },
};
