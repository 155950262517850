<script setup>
import { computed, nextTick, ref, watch } from "vue";
import routeCache from "../../router/routeCache";

const theCheckd = computed(() => routeCache.state.checked);
const tabsRef = ref(null);
watch(
  () => routeCache.state.list.length,
  (n, o) => {
    if (n > o) {
      setTimeout(() => {
        tabsRef.value.setScrollLeft(999999999);
      }, 400);
    }
  }
);
// methods
// methods
</script>

<template>
  <el-scrollbar always
    ref="tabsRef">
    <div class="flex gapS">
      <el-tag style="user-select: none"
        class="c_P"
        v-for="routerPath in routeCache.state.list"
        :key="routerPath"
        size="large"
        @close="routeCache.close(routerPath)"
        @click="routeCache.change(routerPath)"
        :closable="routeCache.state.list.length != 1"
        :checked="theCheckd == routerPath"
        :type="theCheckd == routerPath ? 'primary' : 'info'">
        <span>{{ routeCache.state.store[routerPath].meta.title }}</span>
      </el-tag>
    </div>
  </el-scrollbar>
</template>

<style scoped></style>
