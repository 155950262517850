<script setup>
import { Expand, Fold } from "@element-plus/icons-vue";
import { onMounted, shallowReactive } from "vue";
import roleIds from "../../router/roleIds";
import { useUserStore } from "../../store";
import routeCache from "../../router/routeCache";

const userStore = useUserStore();

const state = shallowReactive({
  collapse: false,
  popoverRender: false,
  nowChoicedMenuId: -1,
});

onMounted(() => {
  state.popoverRender = true;
});
</script>

<template>
  <div
    class="flex _fdC"
    style="transition: width var(--self-transitionTime)"
    :style="{
      width: state.collapse ? '68px' : '10em',
    }">
    <div class="sideMenuBox flex _fdC f1">
      <template v-for="item in userStore.state.menu" :key="item.id">
        <el-popover
          popper-class="popoverBox"
          width="fit-content"
          :append-to="state.popoverRender ? '.menuItemBox' + item.id : ''"
          placement="right-start"
          effect="dark"
          :show-after="200">
          <template #reference>
            <div
              class="menuItemBox"
              :class="[
                'menuItemBox' + item.id,
                state.nowChoicedMenuId == item.id && 'choicedRoute',
              ]">
              <el-icon size="20px">
                <component :is="roleIds.icon[item.id]" />
              </el-icon>
              <span
                :style="{
                  opacity: state.collapse ? 0 : 1,
                  transition: 'opacity var(--self-transitionTime)',
                }">
                {{ item.title }}
              </span>
            </div>
          </template>

          <div class="menuItemContentBox">
            <div v-for="cItem in item.children" :key="cItem.id">
              <div class="childrenItemTitle">{{ cItem.title }}</div>
              <div
                class="routeItem"
                :class="
                  routeCache.state.checked == ccItem.path
                    ? ((state.nowChoicedMenuId = item.id), 'choicedRoute')
                    : ''
                "
                v-for="ccItem in cItem.children"
                :key="ccItem.id"
                @click="routeCache.change(ccItem.path)">
                {{ ccItem.title }}
              </div>
            </div>
          </div>
        </el-popover>
      </template>
    </div>
    <div class="sideCollapseBox" @click="state.collapse = !state.collapse">
      <el-icon style="font-size: 1.25em">
        <component :is="state.collapse ? Expand : Fold"></component>
      </el-icon>
      <span v-if="!state.collapse">收起</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
$sideMenuBoxBGC: #630800;
$mainColor: #fd3904;
.sideMenuBox {
  padding: 8px;
  gap: 8px;

  background-color: $sideMenuBoxBGC;
  transition: all var(--self-transitionTime);
  // overflow-y: scroll;
  color: #fff;
  .menuItemBox,
  .menuItemContentBox .routeItem {
    border-radius: 6px;
    transition: all var(--self-transitionTime);
    &:hover {
      background-color: $mainColor;
    }
  }
  .choicedRoute {
    background-color: #{$mainColor}90;
  }
  .menuItemBox {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: auto;
    border-radius: 6px;
    flex-wrap: nowrap;
    word-break: keep-all;
    :deep(.el-popover.el-popper) {
      background: $sideMenuBoxBGC;
      .el-popper__arrow::before {
        border-color: $sideMenuBoxBGC;
        background: $sideMenuBoxBGC;
      }
    }
  }
  .menuItemContentBox {
    display: flex;
    gap: 16px;
    .childrenItemTitle {
      cursor: auto;
      font-size: 1.2em;
      font-weight: bold;
    }
    .routeItem {
      cursor: pointer;
      min-width: 8em;
      padding: 4px 8px;
      margin-top: 4px;
    }
  }
}
.sideCollapseBox {
  background-color: $sideMenuBoxBGC;
  padding: 16px 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  word-break: keep-all;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}
</style>
