<!--
 * @Description: 
 * @Author: 振顺
 * @Date: 2024-10-17 13:45:10
 * @LastEditTime: 2024-10-22 20:45:37
 * @LastEditors: 振顺
-->
<script setup>
import dayjs from 'dayjs';

const props = defineProps({
  cf: Object,
  data: Array,
});
const value = defineModel("value");
</script>

<template>
  <el-autocomplete v-if="props.cf.type == 'suggestInput'"
    v-model="value"
    clearable
    :debounce="500"
    :value-key="props.cf.valueKey || 'name'"
    :placeholder="`根据${props.cf.label}查询`"
    :fetch-suggestions="props.cf.search"
    @select="props.cf.select"
    v-bind="props.cf.config" />
  <el-tree-select v-else-if="props.cf.type == 'treeSelect'"
    :placeholder="`根据${props.cf.label}查询`"
    :node-key="props.cf.idKey || 'id'"
    :props="{
      label: props.cf.labelKey || 'name',
      children: props.cf.childrenKey || 'childrenList',
    }"
    v-model="value"
    default-expand-all
    filterable
    clearable
    :data="props.data"
    check-strictly
    :render-after-expand="false"
    v-bind="props.cf.config" />
  <el-select v-else-if="props.cf.type == 'select'"
    v-model="value"
    clearable
    filterable
    :placeholder="`根据${props.cf.label}查询`"
    v-bind="props.cf.config">
    <el-option v-for="item in props.data"
      :label="item[props.cf.labelKey || 'name']"
      :key="item[props.cf.idKey || 'id']"
      :value="item[props.cf.idKey || 'id']" />
  </el-select>
  <el-switch v-else-if="props.cf.type == 'switch'"
    v-model="value"
    :active-text="props.cf.true || '是'"
    :inactive-text="props.cf.false || '否'"
    v-bind="props.cf.config" />
  <el-date-picker v-else-if="props.cf.type == 'datePicker'"
    style="width: 100%;"
    v-model="value"
    value-format="YYYY-MM-DD"
    range-separator="至"
    start-placeholder="起始时间"
    end-placeholder="结束时间"
    :placeholder="`根据${props.cf.label}查询`"
    :shortcuts="props.cf.config?.type == 'daterange' && [
      {
        text: '上个月',
        value: () => {
          const today = dayjs(); // 获取当前日期对象
          const lastMonth = today.subtract(1, 'month'); // 获取上个月的日期对象
          return [lastMonth.startOf('month'), lastMonth.endOf('month')]
        },
      }]"
    v-bind="props.cf.config" />
  <el-input v-else
    :placeholder="`根据${props.cf.label}查询`"
    clearable
    v-model="value"
    v-bind="props.cf.config"></el-input>
</template>

<style scoped lang="scss"></style>
