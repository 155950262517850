<script setup>
import { onActivated, onMounted, reactive, ref, shallowReactive, watch, } from "vue";

import { ArrowDown } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import $$ from "../tools";
import { useGlobalStore } from "../store";

const globalStore = useGlobalStore();

const props = defineProps(['type', 'downloadUrl'])

const pageId = {
    '盘点单': 1,
    '物料分类': 2,
    '物料管理': 3,
    'BOM 管理': 4,
    '客户管理': 5,
    '供应商管理': 6,
    '供应商分类': 7,
}[props.type]

// methods
function download() {
    const successCB = (res) => {
        $$.downloadFile(res.data, props.type + '模板')
    };
    const request = {
        url: "system/getTemplateUrl",
        method: "get",
        params: {
            templateType: pageId
        }
    }
    if (pageId == 1) {
        request.url = 'invoicing/downloadInventorySheet'
        delete request.params
    }

    $$.request(request)
        .then(successCB)
}
function upload() {
    $$.uploadFile({
        action: "/work/system/uploadDoc",
        data: {
            parseDocType: pageId,
        },
        'on-success'(e) {
            ElMessage.success('模板数据上传成功')
            globalStore.actions.showTask()
        },
        'on-error'(err) {
            ElMessage.error(err || '上传失败')
            console.log(err);
        },
    })

}
// methods
</script>

<template>

    <el-dropdown>
        <el-button @click=""
            plain
            type="primary">
            导入数据　
            <el-icon>
                <ArrowDown />
            </el-icon>
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="download">下载导入模板</el-dropdown-item>
                <el-dropdown-item @click="upload">通过模板导入</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<style scoped lang="scss"></style>
