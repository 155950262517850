import $$ from ".";
const pageNum = 1,
  pageSize = 10;

export default {
  // 客户
  client(params, cb) {
    const successCB = (res) => {
      cb(res.data.resList);
    };
    $$.request({
      url: "data/getClient",
      method: "get",
      params: {
        simpleFlag: true,
        pageNum,
        pageSize,
        ...params,
      },
    }).then(successCB);
  },
  // 供应商
  supplier(params, cb) {
    const successCB = (res) => {
      cb(res.data);
    };
    $$.request({
      url: "data/getSupplier",
      method: "get",
      params: {
        simpleFlag: true,
        isDelete: false,
        pageNum,
        pageSize,
        ...params,
      },
    }).then(successCB);
  },
  // 仓库列表
  warehouseList(params, cb) {
    const successCB = (res) => {
      cb(res.data);
    };
    $$.request({
      url: "data/getWarehouseList",
      method: "get",
      params: {
        simpleFlag: true,
        ...params,
      },
    }).then(successCB);
  },
  // 材料列表
  materialList(params, cb) {
    const successCB = (res) => {
      cb(res.data.resList);
    };
    $$.request({
      url: "data/getMaterialList",
      method: "get",
      params: {
        simpleFlag: true,
        pageNum,
        pageSize,
        ...params,
      },
    }).then(successCB);
  },
  // BOM列表
  BOMList(params, cb) {
    const successCB = (res) => {
      cb(res.data.bomList);
    };
    $$.request({
      url: "data/getBomList",
      method: "get",
      params: {
        simpleFlag: true,
        pageNum,
        pageSize,
        ...params,
      },
    }).then(successCB);
  },
};
