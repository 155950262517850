<script setup>
import { useUserStore } from "../../store";

const userStore = useUserStore();
</script>

<template>
  <div
    class="ml_M fw_B c_P"
    style="font-size: 1.5em; color: var(--self-mainColor)">
    {{ userStore.state.loginInfo.serviceObjectName }}
  </div>
  <!-- <el-dropdown>
    <div
      class="ml_M fw_B c_P"
      style="font-size: 1.5em; color: var(--self-mainColor)">
      {{ userStore.state.loginInfo.serviceObjectName }}
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="userStore.actions.logout">
          更换主体
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown> -->
</template>

<style scoped lang="scss"></style>
