<script setup>
import $$ from "@/tools";
import { nextTick, onMounted, reactive, ref } from "vue";
import { useUserStore, useGlobalStore } from "../store";
import { ElMessage, ElNotification } from "element-plus";
import RSA from '../tools/RSA'
import SubjectChoice from "../components/PageLogin/SubjectChoice.vue";
import { CircleCloseFilled } from "@element-plus/icons-vue";

const userStore = useUserStore();
const globalStore = useGlobalStore();

// const dropdownRef = ref(null)

const state = reactive({
  userInfo: {
    username: "",
    password: "",
  },
  loading: false,
  choiceSubject: false,
  choiceSubjectId: null,

  remember: true
  , rememberAcc: JSON.parse(localStorage.getItem('rememberAcc') || '[]')
});
const rules = {
  username: [{ required: true, message: "账号不能为空", trigger: "change" }],
  password: [{ required: true, message: "密码不能为空", trigger: "change" }],
};

onMounted(() => {
  if (state.rememberAcc.length) handleSelectAcc(state.rememberAcc[0])
})

// methods
function submitForm() {
  const successCB = (res) => {
    const data = res.data;
    if (data?.serviceObjectConfirmList?.length) {
      data.serviceObjectConfirmList.forEach((item) => {
        const id = `${item.adminId}$${item.serviceObjectId}$${item.addTime}`;

        if (globalStore.state.mainInvitationData[id]) return;
        globalStore.state.mainInvitationData[id] = item;
        item.__notification__ = ElNotification({
          title: "主体加入邀请",
          dangerouslyUseHTMLString: true,
          message: `
  <div class="c_P" style="font-size: 1.1em">
    您有
    <span class="fw_B" style="color: var(--self-mainColor);font-size: 1.1em">
      ${data.serviceObjectConfirmList.length}
    </span>
    条主体加入邀请，点击进行处理
  </div>`,
          duration: 0,
          appendTo: "#globalNotification",
          onClose() {
            delete globalStore.state.mainInvitationData[id];
          },
          onClick() {
            globalStore.state.mainInvitationShow = true;
            globalStore.state.mainInvitationChoice = id;
          },
        });
      });
    }

    if (
      data.serviceObjectList.length == 1 &&
      userStore.actions.checkLoginInfo(data)
    )
      return;
    userStore.state.loginInfo = data;
    userStore.state.token = data.token;

    // [
    //   {
    //     adminName: "13118838529",
    //     adminId: 1,
    //     serviceObjectName: "一味乐",
    //     serviceObjectId: 1,
    //     addTime: "2024-08-12 09:54:03",
    //   },
    // ];
    if (data.serviceObjectList.length == 1) {
      // 记住账号密码
      saveAcc()
      loginSuccess();
    } else if (data.serviceObjectList.length > 1) {
      state.choiceSubject = true;
      state.choiceSubjectId = data.serviceObjectId;
    } else {
      ElMessage.warning("暂无可绑定的主体，请联系管理员");
    }
  };
  state.loading = true;
  $$.request({
    url: "system/login",
    data: {
      account: state.userInfo.username,
      password: RSA.encrypt(state.userInfo.password, RSA.key.password),
    },
  })
    .then(successCB)
    .finally(() => {
      state.loading = false;
    });
}
function changeSubject() {
  const successCB = (res) => {
    if (userStore.actions.checkLoginInfo(res.data)) return;
    ElMessage.success("成功绑定主体");
    Object.assign(userStore.state.loginInfo, res.data);
    userStore.state.loginInfo.isAdmin = userStore.state.loginInfo.moduleIds.includes('s_1')
    loginSuccess();
  };
  state.loading = true;
  $$.request({
    url: "system/updateUserServiceObject",
    data: {
      serviceObjectId: state.choiceSubjectId,
    },
  })
    .then(successCB)
    .catch((err) => {
      state.choiceSubject = false;
    })
    .finally(() => {
      state.loading = false;
    });
}

function loginSuccess() {
  userStore.actions.login();
}
function querySearchAcc(str, cb) {
  cb(str
    ? state.rememberAcc.filter((item) => item.username.includes(str))
    : state.rememberAcc)
}
function handleSelectAcc(item) {
  state.userInfo.username = item.username
  state.userInfo.password = item.password
}
function saveAcc(save = true) {
  const { username, password } = state.userInfo
  const sameOneIdx = state.rememberAcc.findIndex(item => item.username == username)
  if (state.remember && save) {
    if (sameOneIdx == -1) {
      // 没记录过
      state.rememberAcc.unshift({ username, password })
    } else if (sameOneIdx != 0) {
      // 记录过提到最前
      state.rememberAcc.splice(sameOneIdx)
      state.rememberAcc.unshift({ username, password })
    }
  } else {
    if (sameOneIdx != -1) state.rememberAcc.splice(sameOneIdx, 1)
  }
  localStorage.setItem('rememberAcc', JSON.stringify(state.rememberAcc))
}
// methods
</script>

<template>
  <div class="WandH_100p p_R"
    style="background-color: var(--self-darkBackgroundColor)">
    <div class="contentBox absoluteCenter"
      :style="state.choiceSubject && {
        opacity: 0,
        transform: 'translate(-150%, -50%)',
        visibility: 'hidden',
      }
        ">
      <h3 class="title">家具数据管理智能系统</h3>
      <div class="tipTitle">登录您的账户</div>
      <el-form ref="ruleFormRef"
        :model="state.userInfo"
        status-icon
        :rules="rules">
        <el-form-item prop="username">

          <el-autocomplete v-model="state.userInfo.username"
            value-key="username"
            :fetch-suggestions="querySearchAcc"
            clearable
            placeholder="请输入您的账号"
            @select="handleSelectAcc">
            <template #default="{ item }">
              <div class="flex _aiC">
                <span>{{ item.username }}</span>
                <!-- <el-button class="ml_A"
                  style="transform: translateX(20px);"
                  plain
                  text
                  @click.stop="saveAcc(false)"
                  :icon="CircleCloseFilled">
                </el-button> -->
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item style="margin-top: 30px"
          prop="password">
          <el-input class="inputDOM"
            placeholder="请输入您的密码"
            v-model="state.userInfo.password"
            show-password
            type="password"
            autocomplete="off" />
        </el-form-item>
        <el-form-item style="margin-top: 30px">
          <el-button style="width: 100%; height: 36px"
            type="primary"
            :loading="state.loading"
            @click="submitForm">
            登录
          </el-button>
          <div class="loginOption">
            <div>
              <el-checkbox v-model="state.remember"
                label="记住账号" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="contentBox absoluteCenter"
      style="padding: 28px"
      :style="!state.choiceSubject && {
        opacity: 0,
        transform: 'translate(50%, -50%)',
        visibility: 'hidden',
      }
        ">
      <div class="tipTitle">请选择主体</div>

      <div class="toReLoginBtn">
        <el-button text
          type="primary"
          @click="state.choiceSubject = false">
          &lt; 重新登录
        </el-button>
      </div>

      <SubjectChoice v-model:choiceId="state.choiceSubjectId" />

      <el-button style="margin: 30px 30% 0; width: 40%; height: 36px"
        type="primary"
        :loading="state.loading"
        @click="changeSubject">
        进入系统
      </el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contentBox {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 28px 40px 88px;
  width: 400px;
  box-sizing: border-box;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.12));
  transition: all var(--self-transitionTime);
  opacity: 1;

  .title {
    position: absolute;
    top: -50px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -100%);
    text-align: center;
    color: var(--self-mainColor);
    font-size: 20px;
    font-weight: bold;
  }

  .tipTitle {
    margin-bottom: 30px;
    font-size: 18px;
    text-align: center;
  }

  .loginOption {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  .toReLoginBtn {
    position: absolute;
    cursor: pointer;
    user-select: none;
    left: 0px;
    top: 0px;
  }
}
</style>
