import { nextTick, reactive } from "vue";
import _cssVar_ from "../styles/var.js";

// import { ElNotification as _ElNotification_ } from "element-plus";
// export const notification = _ElNotification_;

import _request_ from "./request.js";
import { ElMessage } from "element-plus";
import optionData from "./optionData.js";
import suggest from "./suggest.js";
let _globalId_ = 0;
const $$ = {
  cssVar: _cssVar_,
  request: _request_,
  initFields(fields, option) {
    const res = { search: [], table: [], form: [] };
    // 传配置进来指定要或者不要哪些结果
    if (option) {
      if (
        option.tableUse === false ||
        option.formUse_only ||
        option.searchUse_only
      )
        delete res.table;
      if (
        option.formUse === false ||
        option.tableUse_only ||
        option.searchUse_only
      )
        delete res.form;
      if (
        option.searchUse === false ||
        option.tableUse_only ||
        option.formUse_only
      )
        delete res.search;
    }
    fields.forEach((item) => {
      const result = { label: item.label, key: item.key };
      item = { ...item };

      const tableUse_only =
          option?.tableUse_only === undefined
            ? item.tableUse_only
            : option.tableUse_only,
        formUse_only =
          option?.formUse_only === undefined
            ? item.formUse_only
            : option.formUse_only,
        searchUse_only =
          option?.searchUse_only === undefined
            ? item.searchUse_only
            : option.searchUse_only,
        tableUse =
          option?.tableUse === undefined ? item.tableUse : option.tableUse,
        formUse = option?.formUse === undefined ? item.formUse : option.formUse,
        searchUse =
          option?.searchUse === undefined ? item.searchUse : option.searchUse;

      delete item.tableUse_only;
      delete item.formUse_only;
      delete item.searchUse_only;
      delete item.tableUse;
      delete item.formUse;
      delete item.searchUse;

      // table 表格
      if (res.table && !searchUse_only && !formUse_only && tableUse !== false) {
        res.table.push(item);
        if (tableUse_only) return;
      }

      // search 搜索
      if (
        res.search &&
        !tableUse_only &&
        !formUse_only &&
        (searchUse || searchUse_only) &&
        searchUse !== false
      ) {
        const targetItem = searchUse_only
          ? item
          : {
              ...result,
              ...searchUse,
            };

        res.search.push(targetItem);
        if (searchUse_only) return;
      }

      // form 表单
      if (res.form && !searchUse_only && !tableUse_only && formUse !== false) {
        const targetItem = formUse_only
          ? item
          : {
              ...result,
              ...formUse,
            };

        if (targetItem && targetItem.required) {
          delete targetItem.required;
          if (!targetItem.rule) {
            const isSelect = /(select|date)/i.test(targetItem?.type);
            targetItem.rule = [
              {
                required: true,
                message: `请${isSelect ? "选择" : "输入"}${targetItem.label}`,
                trigger: isSelect ? "change" : "blur",
              },
            ];
          }
        }

        res.form.push(targetItem);
        if (formUse_only) return;
      }
    });
    return res;
  },
  initFormOption(option) {
    const res = reactive({
      loading: false,
      show: false,
      editData: null,
      config: option.config,
      addSubmit: null,
      editSubmit: null,
    });
    if (option.addSubmit) res.addSubmit = option.addSubmit.bind(res);
    if (option.editSubmit) res.editSubmit = option.editSubmit.bind(res);
    return res;
  },
  getGlobalId() {
    return ++_globalId_;
  },
  constant: Object.freeze({
    tableOptionColumn: {
      label: "操作",
      key: "option",
      config: { width: 160 },
      fixed: "right",
      tableUse_only: true,
    },
    loadingBind: {
      "element-loading-text": "加载中...",
      "element-loading-background": "rgba(140, 140, 140, 0.3)",
    },
    useColEditDialog: {
      "label-position": "right",
      "label-width": "auto",
    },
    suggest,
    optionData,
    optionRelation: new Proxy(
      {},
      {
        get: function (target, property) {
          if (!(property in target)) {
            target[property] = optionData[property].reduce((pre, item) => {
              pre[item.id] = item;
              return pre;
            }, {});
          }
          return target[property];
        },
      }
    ),
  }),
  formValidator: {
    file(rule, value, cb) {
      if (Array.isArray(value) && value.length) cb();
      else cb(new Error("请选择附件"));
    },
    number(rule, value, cb) {
      if (
        (!isNaN(value) && (value + "").trim() !== "") ||
        value == undefined ||
        value == ""
      )
        cb();
      else cb(new Error("请输入一个数字"));
    },
  },
  deleteSubmit(url, id, search) {
    const successCB = (res) => {
      search();
      ElMessage.success("删除成功");
    };
    $$.request({
      url,
      data: {
        id,
      },
    }).then(successCB);
  },
  checkNeedUpload(data = []) {
    return new Promise((resolve, reject) => {
      const needUploadList = data.reduce((pre, item, idx) => {
        if (item.raw) {
          item._idx_ = idx;
          pre.push(
            new Promise((resolve_, reject_) => {
              const formData = new FormData();
              formData.append("file", item.raw);
              $$.request({
                url: "system/uploadFile",
                data: formData,
              })
                .then((res) => {
                  res.data._idx_ = idx;
                  res.data.success = true;
                  resolve_(res.data);
                  // "url": "https://cache.yiweile.comfile/2/temp/20240930/IMG_411220240930171722_compress.jpeg",
                  // "filePath": "/2/temp/20240930/IMG_411220240930171722_compress.jpeg"
                })
                .catch((err) => {
                  console.log("文件上传失败", item, err);
                  err._idx_ = idx;
                  err.success = false;
                  resolve_(err);
                });
            })
          );
        }
        return pre;
      }, []);
      Promise.all(needUploadList).then((res) => {
        const successList = [],
          failList = [];
        res.forEach((item) => {
          if (item.success) {
            data.splice(item._idx_, 1, item);
            const regex = /\/([^/?]+)(?:\?|$)/;
            const match = item.url.match(regex);

            if (match) {
              item.name = match[1];
            } else {
              console.log("未找到匹配的文件名");
            }
            successList.push(item);
          } else {
            failList.push(item);
          }
        });
        if (!failList.length) {
          resolve(successList);
          // tag
        } else {
          reject(failList);
          ElMessage.error("部分文件上传失败, 请重新尝试");
        }
      });
    });
  },
  downloadFile(url, filename = "") {
    // 否则触发下载
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = filename; // 设置文件名
    // document.body.appendChild(link);
    // link.click(); // 模拟点击
    // document.body.removeChild(link); // 下载完成后移除链接

    fetch(url, { method: "HEAD" })
      .then((response) => {
        if (response.ok) {
          // 资源存在，使用 a 标签下载
          const link = document.createElement("a");
          link.href = url;
          link.download = filename; // 设置文件名
          document.body.appendChild(link);
          link.click(); // 模拟点击
          document.body.removeChild(link); // 下载完成后移除链接
        } else {
          // 资源不存在，打开新窗口下载
          window.open(url, "_blank");
        }
      })
      .catch((error) => {
        // 请求失败，打开新窗口下载
        window.open(url, "_blank");
        ElMessage.error("文件请求失败:", error);
      });
  },
  showFile(file) {
    let url;
    let filename = file.name; // 默认文件名，你可以根据文件类型动态生成文件名

    if (file.raw && !file.url) {
      // 创建Blob对象
      const blob = new Blob([file.raw], { type: file.raw.type });
      // 创建临时链接
      url = URL.createObjectURL(blob);

      // 判断文件类型，如果是可以直接查看的类型，则用 window.open 打开
      if (
        file.raw.type.startsWith("image/") ||
        file.raw.type === "application/pdf"
      ) {
        // 支持直接查看的文件（例如图片、PDF）
        window.open(url, "_blank");
      } else {
        // 不支持直接查看的文件，触发下载
        const link = document.createElement("a");
        link.href = url;
        link.download = filename; // 设置文件名
        document.body.appendChild(link);
        link.click(); // 模拟点击
        document.body.removeChild(link); // 下载完成后移除链接
      }
    } else {
      // 如果有 url，直接用 url 打开
      url = file.url;
      if (!url) return ElMessage.error("读取下载链接失败");
      if (url.match(/\.(jpg|jpeg|png|gif|bmp|pdf)$/i)) {
        // 如果是图片或PDF，直接打开
        window.open(url, "_blank");
      } else {
        $$.downloadFile(url, filename);
      }
    }

    // 释放临时链接
    URL.revokeObjectURL(url);
  },
  uploadFile(config, successCB) {},
  preview: (url = [], idx = 0) => {},
};
export default $$;
